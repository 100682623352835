export const staticContents = [
  ["about", "about-us"],
  ["ourServices", "our-services"],
  ["missionAndIntent", "mission-and-intent"],
  ["contactUs", "contact-us", "Contact Us"],
  ["MerchantRegister", "Merchant-Register", "Merchant Register"],
  ["servicesForManufacturers", "for-Manufacturers"],
  ["servicesForRetailers", "for-Retailers"],
  ["servicesForSalesAgencies", "for-Sale-Representatives"],
  ["createAMarketplace", "create-a-marketplace"],
  ["privacyAndSecurity", "privacy-and-security"],
  ["socialMediaGroups", "social-media-and-groups"],
  ["partnersAndNetwork", "partners-and-network"],
  ["privacyPolicy", "privacy-policy", "Privacy Policy"],
  ["shippingInformation", "shipping-information", "Shipping Policy"],
  ["returnPolicy", "return-policy", "Return Policy"],
  ["termsOfUse", "terms-of-use", "Terms of Use"],
  ["sellerRegistration", "seller-registration", "Seller Sign Up"],
  ["sellerThankYou", "seller-thank-you", "Thank You"],
]
